import Vue from 'vue/dist/vue.esm';
import axios from '../utils/axios-csrf';

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#subscribe',
    data: {
      email: '',
      message: '',
      addyHandleErrors: null
    },
    components: {},
    methods: {
      subscribe() {       
        this.validateInput();
      },
      validateInput() {
        if (!this.email) this.message = "Please include your email!";
        else this.validateEmail((msg) => {
          this.message = 'Request sent, but something went wrong.'
          this.addyHandleErrors = `${msg}`
        });
      },
      validateEmail(callback) {
        const ifFormatValid = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(this.email);
        if (ifFormatValid) {
          axios.post("/subscribe", {
            email: this.email
          }).then((res) => {
            if (res.data.code !== 200) return callback(res.data.message);

            this.message = "We've added your email to our mailing list!"
            this.email = "";
          })
        } else this.message = "Please include a valid email!";
      }
    }
  });
});

